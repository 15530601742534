import React from "react";
import SeO from "../components/seo";
import pd11_14 from '../data/pdf/legislature/p.d.11_2014.pdf';
import pd11_14_trop_1 from '../data/pdf/legislature/p.d.11_2014_trop.1.pdf';
import pd11_14_trop_2 from '../data/pdf/legislature/p.d.11_2014_trop.2.pdf';

const Legislature = (props) => {
    
        return(
            <React.Fragment>
                <SeO title="Νομοθεσία" description="Νομοθεσία" />
                <ul style={{listStyleType: 'lower-greek'}}>
                    <li style={{paddingBottom: '1em'}}>
                        <a href={pd11_14} target="_blank" rel="noopener noreferrer">ΠΔ 11/2014</a> - Κρίση Σωματικής Ικανότητας των στρατευσίμων, αυτών που κατατάσσονται στις Ένοπλες Δυνάμεις, καθώς και του στρατιωτικού προσωπικού γενικά.
                    </li>
                    <li style={{paddingBottom: '1em'}}>
                        <a href={pd11_14_trop_1} target="_blank" rel="noopener noreferrer">ΦΕΚ 3513Β΄/29-12-2014</a> - Τροποποίηση Πινάκων και Παραρτημάτων ΠΔ 11/2014
                    </li>
                    <li style={{paddingBottom: '1em'}}>
                        <a href={pd11_14_trop_2} target="_blank" rel="noopener noreferrer">ΦΕΚ 1360Β΄/19-04-2019</a> - Τροποποίηση Πινάκων και Παραρτημάτων ΠΔ 11/2014
                    </li>
                </ul>
            </React.Fragment>
        );

}

export default Legislature